import { ErrorBoundary, Show, Suspense, createResource } from "solid-js";
import CTAs from "~/components/Cards/Components/CTAs";
import CardGrid from "~/components/Cards/Components/CardGrid";
import PreviewText from "~/components/Cards/Components/PreviewText";
import { getWrapper } from "~/lib/fetcher";
import VisualContainer from "~/components/Cards/Components/VisualContainer";
import CardShell from "~/components/Cards/CardShell";
import { isOutOfStock, isPreview } from "~/utils/helper_program";

import "./Card.css";
import NouveauNeufLogos from "~/components/shared/NouveauNeufLogos";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import type { ItemReference, SearchParams } from "~/types/drupal_jsonapi";
import { useMapContext } from "~/contexts/MapContext";

export default function Card(props: {
  nid: number;
  initialDeferStream?: boolean;
  item: ItemReference;
  searchParams?: SearchParams;
}) {
  const [wrapper] = createResource(() => props.nid, getWrapper, {
    deferStream: props.initialDeferStream,
  });

  const settings = useDrupalSettingsContext();
  const [, { setMarkerNid }] = useMapContext();

  return (
    <>
      <ErrorBoundary
        fallback={(err, reset) => (
          <p style="border: 1px solid red" onClick={reset}>
            Error: {err.toString()}
          </p>
        )}
      >
        <Suspense
          fallback={<CardShell title={props.item.title} url={props.item.url} />}
        >
          <Show when={wrapper()}>
            <article
              class="node-program-card search-result"
              data-test={`card-program-${wrapper()!.program.field_program_id}`}
              data-ga-zone="card"
              onMouseEnter={() => {
                setMarkerNid!(props.nid);
              }}
            >
              <VisualContainer wrapper={wrapper()!} />

              <div
                class="content-program"
                classList={{
                  "with-nouveau-neuf": settings.nouveau_neuf_enabled,
                }}
              >
                <Show
                  when={!isPreview(wrapper()!)}
                  fallback={
                    <>
                      <PreviewText />
                      <Show
                        when={
                          settings.nouveau_neuf_enabled &&
                          wrapper()!.program.field_segments_mkg &&
                          wrapper()!.program.field_segments_mkg!.length > 0
                        }
                      >
                        <NouveauNeufLogos
                          zone="card"
                          segmentsMkg={wrapper()!.program.field_segments_mkg!}
                        />
                      </Show>
                    </>
                  }
                >
                  <Show when={!isOutOfStock(wrapper()!)}>
                    <CardGrid
                      wrapper={wrapper()!}
                      searchParams={props.searchParams}
                    />
                  </Show>
                </Show>
              </div>

              <CTAs wrapper={wrapper()!} />
            </article>
          </Show>
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
