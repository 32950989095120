import { createMemo, For, Show } from "solid-js";
import { useLotActive } from "~/contexts/LotActiveContext";
import { useEventsContext } from "~/contexts/EventsContext";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import {
  getPlusForTypology,
  getPricesAndReturnRates,
} from "~/utils/helper_lot";

import type { EventZone } from "~/contexts/EventsContext";
import {
  type GridTypology,
  isDateDisplayPricesPast,
} from "~/utils/helper_program";
import type { Lot } from "~/types/drupal_jsonapi";
import type { ProgramWrapper } from "~/utils/program_wrapper";
import {
  formatFloor,
  formatPrice,
  formatSurface,
  sanitizeString,
} from "~/utils/format";
import { useModalFormContext } from "~/contexts/ModalFormContext";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import Accordion from "@corvu/accordion";

import "./GridTypologyRow.css";

import IconExpandMore from "~/img/icons/expand_more.svg";

type GridTypologyRowProps = {
  gridTypology: GridTypology;
  wrapper: ProgramWrapper;
  displayingForCard?: boolean;
};

const gridZone: EventZone = "grid-dwell";

export default function GridTypologyRow(props: GridTypologyRowProps) {
  const [, { sendClick }] = useEventsContext();
  const [, { setMediaOverlay }] = useMediaOverlayContext();
  const [viewportSizeProvider] = useViewportSizeContext();

  const availability = createMemo(() => {
    const count = props.gridTypology.getCurrentStock();
    if (count > 2) {
      return `${count} disponibles`;
    } else if (count === 2) {
      return `<span class="low">Plus que ${count} disponibles !</span>`;
    } else {
      return '<span class="low">Dernier disponible</span>';
    }
  });

  return (
    <>
      <Accordion.Item
        as="div"
        class="grid-typology"
        classList={{
          "displayed-on-card": props.displayingForCard,
        }}
        data-test={`typology-${sanitizeString(props.gridTypology.typology.name)}`}
      >
        <div>
          <Accordion.Trigger
            as="div"
            class="grid-typology-header"
            data-ga-zone="1"
            onClick={() => {
              sendClick("typology-details", gridZone as EventZone);
            }}
          >
            <span class="header-item name" data-test="typology-name">
              {props.gridTypology.typology.name}
            </span>
            <Show when={!props.displayingForCard}>
              <span class="header-item surface" data-test="surface-biggest">
                <small>Jusqu’à</small>{" "}
                <span>
                  {formatSurface(props.gridTypology.getBiggestSurface())}
                </span>
              </span>
            </Show>
            <Show
              fallback={
                <span class="header-item price" data-test="price-smallest">
                  <small>prix</small>{" "}
                  <span itemprop="priceRange">nous consulter</span>
                </span>
              }
              when={
                isDateDisplayPricesPast(props.wrapper) &&
                props.gridTypology.getCheapestPrice()
              }
            >
              <span class="header-item price" data-test="price-smallest">
                <small>à partir de</small>{" "}
                <span itemprop="priceRange">
                  {formatPrice(props.gridTypology.getCheapestPrice()!)}
                </span>
              </span>
            </Show>
            <Show when={!props.displayingForCard}>
              <span
                class="header-item availability"
                data-test="availability"
                innerHTML={availability()}
              />
            </Show>
            <Show when={!props.displayingForCard}>
              <span class="header-item tour" data-test="visit">
                <Show when={props.gridTypology.hasVirtualVisit()}>
                  <button
                    type="button"
                    class="btn"
                    onClick={() => {
                      sendClick("btn-virtual-tour-typology", gridZone);
                      setMediaOverlay(
                        "embed",
                        `<iframe height="100%" width="100%" src="${props.gridTypology.getVirtualVisitUrl()}" allowfullscreen></iframe>`,
                      );
                    }}
                    data-test="btn-visit"
                  >
                    Visite virtuelle
                  </button>
                </Show>
              </span>
            </Show>
            <span class="header-item detail" data-test="visit">
              <i aria-hidden="true" class="cog-icon">
                <IconExpandMore />
              </i>
              <span>Voir le détail</span>
            </span>
          </Accordion.Trigger>
          <Accordion.Content class="lots-container">
            <Show
              when={
                !viewportSizeProvider.viewPortIsLessThan768 &&
                !props.displayingForCard
              }
            >
              <div class="lots-header">
                <span class="lot-number">
                  <span class="inner">Lot</span>
                </span>
                <span class="lot-surface">
                  <span class="inner">Surface</span>
                </span>
                <Show
                  when={props.gridTypology.typology.name !== "Terrains à bâtir"}
                >
                  <span class="lot-floor">
                    <span class="inner">Étage</span>
                  </span>
                </Show>
                <span class="lot-orientation">
                  <span class="inner">Orientation</span>
                </span>
                <span class="lot-price-return-rate">
                  <span class="lot-price">
                    <span class="inner">Prix</span>
                  </span>
                  <span class="lot-return_rate">
                    <span class="inner">Rentabilité</span>
                  </span>
                </span>
                <span class="lot-plus">
                  <span class="inner">Les +</span>
                </span>
                <span class="lot-segments">
                  <span class="inner">&nbsp;</span>
                </span>
                <span class="lot-actions">&nbsp;</span>
              </div>
            </Show>
            <Show
              when={
                viewportSizeProvider.viewPortIsLessThan768 ||
                props.displayingForCard
              }
              fallback={
                <GridTypologyRowLotsDesktop
                  gridTypology={props.gridTypology}
                  wrapper={props.wrapper}
                />
              }
            >
              <GridTypologyRowLotsMobile
                gridTypology={props.gridTypology}
                wrapper={props.wrapper}
              />
            </Show>
          </Accordion.Content>
        </div>
      </Accordion.Item>
    </>
  );
}

function GridTypologyRowLotsMobile(props: {
  gridTypology: GridTypology;
  wrapper: ProgramWrapper;
}) {
  return (
    <>
      <Accordion collapseBehavior="hide">
        <For each={props.gridTypology.lots}>
          {(lot) => (
            <GridTypologyRowLotMobile lot={lot} wrapper={props.wrapper} />
          )}
        </For>
      </Accordion>
    </>
  );
}

type GridTypologyRowLotMobileProps = {
  lot: Lot;
  wrapper: ProgramWrapper;
};

function GridTypologyRowLotMobile(props: GridTypologyRowLotMobileProps) {
  const minPrice = createMemo(() => {
    const prices: number[] = [];
    Object.entries(getPricesAndReturnRates(props.wrapper, props.lot)).forEach(
      ([, priceGroup]) => {
        if (priceGroup?.price_raw) {
          return prices.push(priceGroup.price_raw);
        }
      },
    );
    return Math.min(...prices) !== 0 ? Math.min(...prices) : undefined;
  });

  return (
    <>
      <Accordion.Item
        as="div"
        class="lot-row-mobile-container"
        data-test={`lot-${props.lot.number}`}
        data-ga-zone="2"
        data-lot-eid={props.lot.drupal_internal__id}
      >
        <Accordion.Trigger as="div" class="lot-row-mobile">
          <span data-test="number" class="row-item row-number">
            <span class="inner">{props.lot.number}</span>
          </span>
          <span data-test="surface" class="row-item row-surface">
            <span class="inner">
              <Show when={props.lot.surface} fallback="-">
                {formatSurface(props.lot.surface!, 0, 0)}
              </Show>
            </span>
          </span>
          <span data-test="price" class="row-item row-price">
            <span class="label">
              {isDateDisplayPricesPast(props.wrapper) ? "à partir de" : "prix"}
            </span>
            <span class="price">
              {isDateDisplayPricesPast(props.wrapper) && minPrice()
                ? formatPrice(minPrice()!)
                : "nous consulter"}
            </span>
          </span>
          <span class="row-item row-actions">
            <span class="inner">
              <i aria-hidden="true" class="cog-icon">
                <IconExpandMore />
              </i>
            </span>
          </span>
        </Accordion.Trigger>
        <Accordion.Content as="div" class="lot-details-container">
          <GridTypologyLotDetailsForMobile
            lot={props.lot}
            wrapper={props.wrapper}
          />
        </Accordion.Content>
      </Accordion.Item>
    </>
  );
}

function GridTypologyLotDetailsForMobile(props: {
  lot: Lot;
  wrapper: ProgramWrapper;
}) {
  const [, { sendShowEvent }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();
  const [, { setMediaOverlay }] = useMediaOverlayContext();
  const [, { sendClick }] = useEventsContext();
  const [, { setLot }] = useLotActive();
  const settings = useDrupalSettingsContext();

  const lesPlus = createMemo(() => {
    return getPlusForTypology(props.wrapper, props.lot);
  });

  return (
    <>
      <div class="lot-details">
        <Show when={props.lot.re_core_entities_lot_type.name !== "Terrain"}>
          <div class="lot-detail" data-test="floor">
            <span class="label">Étage</span>
            <span
              class="value lot-floor"
              innerHTML={props.lot.floor ? formatFloor(props.lot.floor) : "-"}
            />
          </div>
        </Show>
        <div class="lot-detail" data-test="orientation">
          <span class="label">Orientation</span>
          <span class="value lot-orientation">
            {props.lot.orientation ? props.lot.orientation : "-"}
          </span>
        </div>
        <div class="lot-price-return-rate">
          <span class="labels">
            <span>Prix</span>
            <span>Rentabilité</span>
          </span>
          <span class="values">
            <For
              each={Object.values(
                getPricesAndReturnRates(props.wrapper, props.lot),
              )}
            >
              {(priceGroup) => (
                <Show when={priceGroup?.price}>
                  <span class="value">
                    <span class="group-prices">
                      <span data-test={priceGroup?.data_test} class="lot-price">
                        <span
                          class="label"
                          innerHTML={priceGroup?.price_label}
                        />
                        <span class="price">{priceGroup?.price}</span>
                      </span>
                    </span>
                    <span class="group-return-rates">
                      <Show
                        when={
                          priceGroup?.return_rates.length &&
                          priceGroup?.return_rates.length > 0
                        }
                        fallback={<span class="lot-return-rate">-</span>}
                      >
                        <For each={priceGroup?.return_rates}>
                          {(return_rate) => (
                            <span
                              data-test={return_rate.data_test}
                              class="lot-return-rate"
                            >
                              <span class="label">{return_rate.label}</span>
                              <span class="return-rate">
                                {return_rate.rate}
                              </span>
                            </span>
                          )}
                        </For>
                      </Show>
                    </span>
                  </span>
                </Show>
              )}
            </For>
          </span>
        </div>
        <div class="lot-detail" data-test="plus">
          <span class="label">Les +</span>
          <span class="value lot-plus">
            <Show when={lesPlus()} fallback="-">
              {lesPlus()}
            </Show>
          </span>
        </div>
        <Show
          when={
            props.lot.field_is_access ||
            props.lot.field_is_invest ||
            props.lot.field_is_signature
          }
        >
          <div class="lot-detail segments" data-test="segments">
            <LotSegmentsMkg lot={props.lot} />
          </div>
        </Show>
        <span class="lot-detail lot-actions">
          <Show when={props.lot.blueprint?.uri?.url}>
            <Show
              when={settings.form_blueprint_bypass}
              fallback={
                <button
                  type="button"
                  class="btn btn-blueprint"
                  data-test="cta-blueprint"
                  onClick={() => {
                    sendShowEvent(`blueprint`, gridZone, {
                      nid: props.wrapper.program.drupal_internal__nid,
                    });
                    openModalForm!("blueprint", {
                      wrapper: props.wrapper,
                      lot: props.lot,
                    });
                    setModalFormHeadline!("Télécharger le plan");
                  }}
                >
                  Télécharger le plan
                </button>
              }
            >
              <a
                href={props.lot.blueprint?.uri?.url}
                class="btn btn-blueprint"
                data-test="btn-blueprint"
                target="_blank"
                onClick={() => {
                  sendClick(`btn-blueprint`, gridZone);
                }}
                rel="noindex nofollow"
              >
                Télécharger le plan
              </a>
            </Show>
          </Show>
          <Show when={props.lot.blueprint_3d_url}>
            <button
              type="button"
              class="btn btn-visit"
              data-test="btn-3d-lot"
              onClick={() => {
                sendClick("btn-3d-lot", gridZone);
                if (
                  props.lot.blueprint_3d_url.substring(
                    props.lot.blueprint_3d_url.length - 4,
                  ) === ".jpg"
                ) {
                  setMediaOverlay("images", props.lot.blueprint_3d_url);
                } else {
                  setMediaOverlay(
                    "embed",
                    `<iframe height="100%" width="100%" src="${props.lot.blueprint_3d_url}" allowfullscreen></iframe>`,
                  );
                }
              }}
            >
              Visiter en 3D
            </button>
          </Show>
          <button
            type="button"
            class="btn"
            data-test="cta-drawer"
            onClick={() => {
              sendClick("btn-lot-details", gridZone);
              setLot!(props.wrapper, props.lot);
            }}
          >
            + d’infos
          </button>
        </span>
      </div>
    </>
  );
}

function GridTypologyRowLotsDesktop(props: {
  gridTypology: GridTypology;
  wrapper: ProgramWrapper;
}) {
  return (
    <>
      <For each={props.gridTypology.lots}>
        {(lot) => (
          <GridTypologyRowLotDesktop lot={lot} wrapper={props.wrapper} />
        )}
      </For>
    </>
  );
}

function GridTypologyRowLotDesktop(props: {
  lot: Lot;
  wrapper: ProgramWrapper;
}) {
  const [, { sendShowEvent }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();
  const [, { sendClick }] = useEventsContext();
  const [, { setMediaOverlay }] = useMediaOverlayContext();
  const [, { setLot }] = useLotActive();
  const settings = useDrupalSettingsContext();

  const lesPlus = createMemo(() => {
    return getPlusForTypology(props.wrapper, props.lot);
  });

  return (
    <>
      <div
        class="lot-row-desktop"
        data-ga-zone="2"
        data-test={`lot-${props.lot.number}`}
        data-lot-eid={props.lot.drupal_internal__id}
      >
        <span data-test="number" class="row-item row-number">
          <span class="inner">{props.lot.number}</span>
        </span>
        <span data-test="surface" class="row-item row-surface">
          <span class="inner">
            <Show when={props.lot.surface} fallback="-">
              {formatSurface(props.lot.surface!, 0, 0)}
            </Show>
          </span>
        </span>
        <Show when={props.lot.re_core_entities_lot_type.name !== "Terrain"}>
          <span data-test="floor" class="row-item row-floor">
            <span
              class="inner"
              innerHTML={props.lot.floor ? formatFloor(props.lot.floor) : "-"}
            />
          </span>
        </Show>
        <span data-test="orientation" class="row-item row-orientation">
          <span class="inner">
            {props.lot.orientation ? props.lot.orientation : "-"}
          </span>
        </span>
        <span class="row-item row-price-return-rate">
          <For
            each={Object.values(
              getPricesAndReturnRates(props.wrapper, props.lot),
            )}
          >
            {(priceGroup) => (
              <Show when={priceGroup?.price}>
                <span class="inner">
                  <span class="group-prices">
                    <span
                      data-test={priceGroup?.data_test}
                      class="row-item row-price"
                    >
                      <span class="label" innerHTML={priceGroup?.price_label} />
                      <span class="price">{priceGroup?.price}</span>
                    </span>
                  </span>
                  <span class="group-return-rates">
                    <Show
                      when={
                        priceGroup?.return_rates.length &&
                        priceGroup?.return_rates.length > 0
                      }
                      fallback={<span class="row-item row-return-rate">-</span>}
                    >
                      <For each={priceGroup?.return_rates}>
                        {(return_rate) => (
                          <span
                            data-test={return_rate.data_test}
                            class="row-item row-return-rate"
                          >
                            <span class="label">{return_rate.label}</span>
                            <span class="return-rate">{return_rate.rate}</span>
                          </span>
                        )}
                      </For>
                    </Show>
                  </span>
                </span>
              </Show>
            )}
          </For>
        </span>
        <span data-test="plus" class="row-item row-plus">
          <span class="inner">
            <Show when={lesPlus()} fallback="-">
              {lesPlus()}
            </Show>
          </span>
        </span>
        <span data-test="segments" class="row-item row-segments">
          <span class="inner">
            <LotSegmentsMkg lot={props.lot} />
          </span>
        </span>
        <span class="row-item row-actions">
          <span class="inner">
            <Show when={props.lot.blueprint?.uri?.url}>
              <Show
                when={settings.form_blueprint_bypass}
                fallback={
                  <button
                    type="button"
                    class="btn btn-blueprint"
                    data-test="cta-blueprint"
                    onClick={() => {
                      sendShowEvent(`blueprint`, gridZone, {
                        nid: props.wrapper.program.drupal_internal__nid,
                      });
                      openModalForm!("blueprint", {
                        wrapper: props.wrapper,
                        lot: props.lot,
                      });
                      setModalFormHeadline!("Télécharger le plan");
                    }}
                  >
                    Télécharger le plan
                  </button>
                }
              >
                <a
                  href={props.lot.blueprint?.uri?.url}
                  class="btn btn-blueprint"
                  data-test="btn-blueprint"
                  target="_blank"
                  onClick={() => {
                    sendClick(`btn-blueprint`, gridZone);
                  }}
                  rel="noindex nofollow"
                >
                  Télécharger le plan
                </a>
              </Show>
            </Show>
            <Show when={props.lot.blueprint_3d_url}>
              <button
                type="button"
                class="btn btn-visit"
                data-test="btn-3d-lot"
                onClick={() => {
                  sendClick("btn-3d-lot", gridZone);
                  if (
                    props.lot.blueprint_3d_url.substring(
                      props.lot.blueprint_3d_url.length - 4,
                    ) === ".jpg"
                  ) {
                    setMediaOverlay("images", props.lot.blueprint_3d_url);
                  } else {
                    setMediaOverlay(
                      "embed",
                      `<iframe height="100%" width="100%" src="${props.lot.blueprint_3d_url}" allowfullscreen></iframe>`,
                    );
                  }
                }}
              >
                Visiter en 3D
              </button>
            </Show>
            <button
              type="button"
              class="btn"
              data-test="cta-drawer"
              onClick={() => {
                sendClick("btn-lot-details", gridZone);
                setLot!(props.wrapper, props.lot);
              }}
            >
              + d’infos
            </button>
          </span>
        </span>
      </div>
    </>
  );
}

function LotSegmentsMkg(props: { lot: Lot }) {
  const settings = useDrupalSettingsContext();

  return (
    <>
      <Show when={props.lot.field_is_access}>
        <span class="segment" data-test="access">
          <img
            src={settings.marketing_segments_terms.access.field_logo_variante_1}
            alt="Cogedim Access"
            height="36"
            width="36"
          />
          <span class="amount-group">
            <Show when={props.lot.field_access_mp_amount}>
              <span class="amount" data-test="amount">
                <strong>
                  {formatPrice(props.lot.field_access_mp_amount!)}
                </strong>{" "}
                /mois
              </span>
            </Show>
            <br />
            <Show when={props.lot.field_access_mp_legals}>
              <a
                href={props.lot.field_access_mp_legals}
                target="_blank"
                data-test="legals"
              >
                Voir les conditions
              </a>
            </Show>
          </span>
        </span>
      </Show>
      <Show when={props.lot.field_is_invest}>
        <span class="segment" data-test="invest">
          <img
            src={settings.marketing_segments_terms.invest.field_logo_variante_1}
            alt="Cogedim Invest"
            height="36"
            width="36"
          />
        </span>
      </Show>
      <Show when={props.lot.field_is_signature}>
        <span class="segment" data-test="signature">
          <img
            src={
              settings.marketing_segments_terms.signature.field_logo_variante_1
            }
            alt="Cogedim Signature"
            height="36"
            width="36"
          />
        </span>
      </Show>
    </>
  );
}
